<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }" class="table-form">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="12" sm="12">
                          <b-table-simple bordered>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.fiscal_year_bn : accommodation.fiscal_year }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('org_pro.organization') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.org_bn : accommodation.org }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('org_pro.office_type') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.office_type_bn : accommodation.office_type }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('org_pro.office_namel') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.office_name_bn : accommodation.office_name }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('elearning_config.guest_house_name') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.guest_house_bn : accommodation.guest_house }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('globalTrans.email') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.email }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.check_in') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.check_in | dateFormat }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.check_out') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.check_out | dateFormat }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:20%" class="text-right">{{ $t('elearning_accommodation.org_name') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:27%" class="text-left">{{ ($i18n.locale=='bn') ? accommodation.guest_org_bn : accommodation.guest_org }}</b-td>
                                <b-th style="width:14%" class="text-right">{{ $t('elearning_accommodation.number_of_days') }}</b-th>
                                <b-th style="width:3%" class="text-center">:</b-th>
                                <b-td style="width:33%" class="text-left">{{ accommodation.no_of_days }}</b-td>
                              </b-tr>
                          </b-table-simple>
                          <b-table-simple bordered>
                            <b-tr>
                              <b-th class="text-center">{{ $t('elearning_accommodation.bill_details') }}</b-th>
                            </b-tr>
                          </b-table-simple>
                          <b-table-simple bordered>
                              <b-tr>
                                  <b-th style="width:20%">{{ $t('elearning_config.room_type') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_config.floor_no') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_config.room_no') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_config.room_rent') }}</b-th>
                                  <b-th style="width:15%">{{ $t('elearning_accommodation.number_of_days') }}</b-th>
                                  <b-th style="width:20%">{{ $t('elearning_accommodation.bill_amount') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in accommodation.accommodation_room_details" :key="index">
                                <b-td style="width:20%">{{ $store.state.TrainingElearning.commonObj.roomTypeList.find(doc => doc.value === parseInt(item.room_type_id)).text_en }}</b-td>
                                <b-td style="width:15%">{{ item.floor_no }}</b-td>
                                <b-td style="width:15%">{{ $store.state.TrainingElearning.commonObj.roomList.find(doc => doc.value === parseInt(item.room_no)).text_en }}</b-td>
                                <b-td style="width:15%">{{ $n(item.room_rent) }}</b-td>
                                <b-td style="width:15%">{{ $n(accommodation.no_of_days) }}</b-td>
                                <b-td style="width:20%">{{ $n(item.room_rent * accommodation.no_of_days) }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                <b-td>{{ $n(accommodation.total_rent) }}</b-td>
                              </b-tr>
                          </b-table-simple>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Tax" vid='tax' rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="tax"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_accommodation.tax')}}
                              </template>
                              <b-form-input
                                  id="tax"
                                  v-model="formData.tax"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Total Amount" vid='total_amount' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="total_amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_accommodation.total_amount')}}
                              </template>
                              <b-form-input
                                  readonly
                                  id="total_amount"
                                  v-model="formData.total_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="4" sm="12">
                          <ValidationProvider name="Net Paid Amount" vid='net_paid_amount' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="net_paid_amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_accommodation.net_paid_amount')}}
                              </template>
                              <b-form-input
                                  id="net_paid_amount"
                                  v-model="formData.net_paid_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col> -->
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="remarks"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.remarks_en')}}
                                  </template>
                                  <b-form-textarea
                                      rows="2"
                                      id="remarks"
                                      v-model="formData.remarks"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="remarks_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_bn')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks_bn"
                                        v-model="formData.remarks_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 12px;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billGenerateStore, accommodationManagementShow } from '../../api/routes'
import ExportPdf from './export_pdf_bill'

export default {
  props: ['id'],
  components: {
    ValidationObserver, ValidationProvider
  },
  created () {
    if (this.id) {
        this.getAccommodationData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      loading: false,
      formData: {
        id: '',
        accommodation_management_id: this.id,
        total_amount: '',
        tax: 0,
        check_out: '',
        is_check_out: 1,
        remark: '',
        remark_bn: '',
        status: 1
      },
      officeTypeList: [],
      accommodation: {},
      guestHouseList: [],
      officeList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
        this.guestHouseList = this.getGuestHouseList(newValue)
    },
    'formData.tax': function (newValue) {
        this.officeTypeList = this.getTotal(newValue)
    }
  },
  methods: {
    async getAccommodationData () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, accommodationManagementShow + '/' + this.id)
      this.accommodation = JSON.parse(JSON.stringify(result.data))
      const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(this.accommodation.org_id))
      const orgData = {}
      if (typeof orgObj !== 'undefined') {
        orgData.org = orgObj.text_en
        orgData.org_bn = orgObj.text_bn
      } else {
        orgData.org = ''
        orgData.org_bn = ''
      }

      const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(this.accommodation.guest_house_id))
      const guestHouseData = {}
      if (typeof guestHouseObj !== 'undefined') {
        guestHouseData.guest_house = guestHouseObj.text_en
        guestHouseData.guest_house_bn = guestHouseObj.text_bn
      } else {
        guestHouseData.guest_house = ''
        guestHouseData.guest_house_bn = ''
      }

      const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(this.accommodation.office_type_id))
      const officeTypeData = {}
      if (typeof officeTypeObj !== 'undefined') {
        officeTypeData.office_type = officeTypeObj.text_en
        officeTypeData.office_type_bn = officeTypeObj.text_bn
      } else {
        officeTypeData.office_type = ''
        officeTypeData.office_type_bn = ''
      }

      const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(this.accommodation.fiscal_year_id))
      const fiscalYearData = {}
      if (typeof fiscalYearObj !== 'undefined') {
        fiscalYearData.fiscal_year = fiscalYearObj.text_en
        fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
      } else {
        fiscalYearData.fiscal_year = ''
        fiscalYearData.fiscal_year_bn = ''
      }

      const OfficeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(this.accommodation.office_id))
      const OfficeData = {}
      if (typeof OfficeObj !== 'undefined') {
        OfficeData.office_name = OfficeObj.text_en
        OfficeData.office_name_bn = OfficeObj.text_bn
      } else {
        OfficeData.office_name = ''
        OfficeData.office_name_bn = ''
      }

      const foodPackageObj = this.$store.state.TrainingElearning.commonObj.foodPackageList.find(doc => doc.value === parseInt(this.accommodation.food_package_id))
      const foodPackageData = {}
      if (typeof foodPackageObj !== 'undefined') {
        foodPackageData.foor_package = foodPackageObj.text_en
        foodPackageData.foor_package_bn = foodPackageObj.text_bn
      } else {
        foodPackageData.foor_package = ''
        foodPackageData.foor_package_bn = ''
      }
      this.accommodation.accommodation_room_details = this.accommodation.accommodation_room_details.map(item => {
        const roomTypeObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(roomTypeList => roomTypeList.value === parseInt(item.room_type_id))
        const roomTypeData = {}
        if (typeof roomTypeObj !== 'undefined') {
          roomTypeData.room_type = roomTypeObj.text_en
          roomTypeData.room_type_bn = roomTypeObj.text_bn
        } else {
          roomTypeData.room_type = ''
          roomTypeData.room_type_bn = ''
        }
        const roomObj = this.$store.state.TrainingElearning.commonObj.roomList.find(roomTypeList => roomTypeList.value === parseInt(item.room_no))
        const roomData = {}
        if (typeof roomObj !== 'undefined') {
          roomData.room = roomObj.text_en
          roomData.room_bn = roomObj.text_bn
        } else {
          roomData.room = ''
          roomData.room_bn = ''
        }
        return Object.assign({}, item, roomTypeData, roomData)
      })
      officeTypeData.total = this.accommodation.total_rent
      const guestOrgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(this.accommodation.guest_org_id))
      const guestOrgData = {}
      if (typeof guestOrgObj !== 'undefined') {
        guestOrgData.guest_org = guestOrgObj.text_en
        guestOrgData.guest_org_bn = guestOrgObj.text_bn
      } else {
        guestOrgData.guest_org = ''
        guestOrgData.guest_org_bn = ''
      }
      this.accommodation = Object.assign({}, this.accommodation, orgData, guestHouseData, OfficeData, fiscalYearData, officeTypeData, foodPackageData, guestOrgData)
      this.formData.total_amount = this.accommodation.total_rent
      this.loading = false
    },
    getOfficeTypeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    getOfficeList (officeTypeId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
            return officeList.filter(office => office.office_type_id === officeTypeId)
        }
        return officeList
    },
    getTotal (tax) {
      const taxv = (parseFloat(tax) / 100) * parseFloat(this.accommodation.total)
      const gTotal = parseFloat(this.accommodation.total) - taxv
      this.formData.total_amount = gTotal
    },
    getGuestHouseList (officeTypeId = null) {
        const houseList = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
        if (officeTypeId) {
            return houseList.filter(office => office.office_type_id === officeTypeId)
        }
        return houseList
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      // get checkout date
      var dateObj = new Date()
      var month = dateObj.getUTCMonth() + 1
      var day = dateObj.getUTCDate()
      var year = dateObj.getUTCFullYear()
      const newdate = year + '-' + month + '-' + day
      this.formData.check_out = newdate
      const loadinState = { loading: false, listReload: false }

      result = await RestApi.postData(trainingElearningServiceBaseUrl, billGenerateStore, this.formData)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-6')
      } else {
        if (result.errors) {
          this.$toast.error({
          title: 'Error',
          message: 'This person already Checked Out!',
          color: '#D6E09B'
        })
        }
        this.$refs.form.setErrors(result.errors)
      }
    },
    pdfExport1 () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Bill Memo' : 'বিল মেমো'
        ExportPdf.exportPdfDetails(reportTitle, this.accommodation, this)
    }
  }
}
</script>
